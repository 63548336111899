import React from 'react';
import styles from './About.module.css';

const About = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>About HindiTTS.com</h1>
      <p className={styles.content}>
        HindiTTS.com is a free online tool that converts Hindi text to natural-sounding speech. 
        Our service uses advanced text-to-speech technology to provide high-quality audio output 
        with multiple voice options. Whether you're learning Hindi, need audio content for your 
        project, or require accessibility features, HindiTTS.com is here to help.
      </p>
      <h2 className={styles.subtitle}>Features:</h2>
      <ul className={styles.featureList}>
        <li>Multiple Hindi voice options</li>
        <li>Up to 250 characters per conversion</li>
        <li>Easy-to-use interface</li>
        <li>Download audio as MP3</li>
        <li>Completely free to use</li>
      </ul>
    </div>
  );
};

export default About;