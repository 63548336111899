import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import styles from './Navbar.module.css';

const Navbar = () => {
  const { isAuthenticated, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <nav className={styles.navbar}>
      <ul className={styles.navList}>
        <li><Link to="/" className={styles.navLink}>Home</Link></li>
        <li><Link to="/about" className={styles.navLink}>About</Link></li>
        <li><Link to="/contact" className={styles.navLink}>Contact</Link></li>
        {!isAuthenticated ? (
          <>
            <li><Link to="/signup" className={styles.navLink}>Sign Up</Link></li>
            <li><Link to="/login" className={styles.navLink}>Login</Link></li>
          </>
        ) : (
          <li><button onClick={handleLogout} className={styles.navLinkSignUp}>Logout</button></li>
        )}
      </ul>
    </nav>
  );
};

export default Navbar;