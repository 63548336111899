import React from 'react';
import styles from './Footer.module.css';

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <p>&copy; 2024 HindiTTS.com - All rights reserved.</p>
            <p>Free Hindi Text to Speech Converter</p>
        </footer>
    );
};

export default Footer;