import React from 'react';
import styles from './Contact.module.css';

const Contact = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Contact Us</h1>
      <p className={styles.content}>
        We'd love to hear from you! If you have any questions, suggestions, or feedback 
        about HindiTTS.com, please don't hesitate to get in touch.
      </p>
      <div className={styles.contactInfo}>
        <p>Email: contact@hinditts.com</p>
      </div>
    </div>
  );
};

export default Contact;