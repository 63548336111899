// components/AdUnit.js
import React, { useEffect, useRef } from 'react';

const AdUnit = () => {
  const adRef = useRef(null);

  useEffect(() => {
    try {
      // Check if adsbygoogle is loaded
      if (window.adsbygoogle) {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      }
    } catch (error) {
      console.error('Error loading ad:', error);
    }
  }, []);

  return (
    <ins
      ref={adRef}
      className="adsbygoogle"
      style={{ display: 'block' }}
      data-ad-format="fluid"
      data-ad-layout-key="-6t+ed+2i-1n-4w"
      data-ad-client="ca-pub-5399878472989914"
      data-ad-slot="your-ad-slot-id"  // Replace with your actual ad slot ID from AdSense
    />
  );
};

export default AdUnit;