import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import styles from './Home.module.css';
import AdUnit from '../components/AdUnit';
import { Speaker, Download, Volume2 } from 'lucide-react';
import { Link } from 'react-router-dom';

const Home = () => {
  const { isAuthenticated, userRole } = useContext(AuthContext);
  const [text, setText] = useState('');
  const [language, setLanguage] = useState('');
  const [voice, setVoice] = useState('');
  const [languages, setLanguages] = useState([]);
  const [voices, setVoices] = useState([]);
  const [audioSrc, setAudioSrc] = useState('');
  const [audioBlob, setAudioBlob] = useState(null);
  const [error, setError] = useState('');
  const [isConverting, setIsConverting] = useState(false);
  
  const isMaster = userRole === 'master';

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await axios.get('/api/tts/languages');
        setLanguages(response.data);
        if (response.data.length > 0) {
          setLanguage(response.data[0].code);
        }
      } catch (error) {
        console.error('Failed to fetch languages:', error);
        setError(`Failed to load languages. Error: ${error.message}`);
      }
    };

    fetchLanguages();
  }, []);

  useEffect(() => {
    const fetchVoices = async () => {
      if (!language) return;
      try {
        const response = await axios.get(`/api/tts/voices/${language}`);
        setVoices(response.data);
        if (response.data.length > 0) {
          setVoice(response.data[0].id);
        }
      } catch (error) {
        console.error('Failed to fetch voices:', error);
        setError(`Failed to load voices. Error: ${error.message}`);
      }
    };

    fetchVoices();
  }, [language]);

  const handleDownload = () => {
    if (audioBlob) {
      const url = URL.createObjectURL(audioBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'audio.mp3';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  };

  const handleConvert = async () => {
    setError('');
    setIsConverting(true);

    if (!isAuthenticated) {
      setError('Please sign up or log in to use the text-to-speech converter');
      setIsConverting(false);
      return;
    }

    if (!text) {
      setError('Please enter some text.');
      setIsConverting(false);
      return;
    }

    try {
      const response = await axios.post('/api/tts/convert', 
        { text, voice, languageCode: language },
        {
          responseType: 'blob',
          headers: {
            'x-auth-token': localStorage.getItem('token')
          }
        }
      );
      
      const blob = new Blob([response.data], { type: 'audio/mp3' });
      const audioUrl = URL.createObjectURL(blob);
      setAudioSrc(audioUrl);
      setAudioBlob(blob);
    } catch (error) {
      setError(
        error.response?.data?.error || 
        'Failed to convert text to speech. Please try again.'
      );
    } finally {
      setIsConverting(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.heroSection}>
        <Speaker size={48} className={styles.heroIcon} />
        <h1 className={styles.title}>Text to Speech Converter</h1>
        <p className={styles.subtitle}>
          Convert text to natural-sounding speech in multiple languages
        </p>
      </div>

      <div className={styles.mainContent}>
        <div className={styles.cardContainer}>
          <div className={styles.card}>
            <div className={styles.cardHeader}>
              <div className={styles.languageSelector}>
                <select
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                  className={styles.select}
                  disabled={!isAuthenticated} // Disable if not authenticated
                >
                  {languages.map((lang) => (
                    <option key={lang.code} value={lang.code}>
                      {lang.name}
                    </option>
                  ))}
                </select>
                <select
                  value={voice}
                  onChange={(e) => setVoice(e.target.value)}
                  className={styles.select}
                  disabled={voices.length === 0 || !isAuthenticated} // Disable if not authenticated
                >
                  {voices.map((v) => (
                    <option key={v.id} value={v.id}>
                      {v.name}
                    </option>
                  ))}
                </select>
              </div>
              {!isMaster && (
                <div className={styles.characterLimit}>
                  {text.length}/100 characters
                </div>
              )}
            </div>

            <div className={styles.textareaContainer}>
              {isAuthenticated ? (
                <textarea
                  className={styles.textarea}
                  placeholder="Enter your text here..."
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  maxLength={isMaster ? undefined : 100}
                />
              ) : (
                <div className={styles.disabledTextarea}>
                  <textarea
                    className={styles.textarea}
                    placeholder="Please sign up or log in to use this feature..."
                    value={text}
                    onChange={(e) => setText(e.target.value)} // Prevent changing the value
                    disabled
                  />
                  <div className={styles.authPrompt}>
                    <h2>Sign Up to Start Converting</h2>
                    <p>Create a free account to use our text-to-speech converter</p>
                    <div className={styles.authButtons}>
                      <Link to="/signup" className={styles.authButton}>
                        Sign Up
                      </Link>
                      <Link to="/login" className={styles.authButton}>
                        Login
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className={styles.cardFooter}>
              {isAuthenticated && (
                <button 
                  className={styles.convertButton}
                  onClick={handleConvert}
                  disabled={isConverting || !text.trim()}
                >
                  {isConverting ? (
                    <span className={styles.loadingSpinner}></span>
                  ) : (
                    <>
                      <Volume2 size={20} />
                      Convert to Speech
                    </>
                  )}
                </button>
              )}
            </div>
          </div>

          {error && <div className={styles.errorMessage}>{error}</div>}

          {audioSrc && (
            <div className={styles.audioCard}>
              <audio className={styles.audioPlayer} controls src={audioSrc}></audio>
              <button 
                className={styles.downloadButton}
                onClick={handleDownload}
              >
                <Download size={20} />
                Download MP3
              </button>
            </div>
          )}
        </div>

        <div className={styles.adSection}>
          <AdUnit />
        </div>
      </div>

      <div className={styles.featuresSection}>
        <div className={styles.featureCard}>
          <h3>Multiple Languages</h3>
          <p>Support for various languages and accents</p>
        </div>
        <div className={styles.featureCard}>
          <h3>Natural Voices</h3>
          <p>High-quality, natural-sounding speech synthesis</p>
        </div>
        <div className={styles.featureCard}>
          <h3>Easy Download</h3>
          <p>Download converted audio in MP3 format</p>
        </div>
      </div>
    </div>
  );
};

export default Home;
